import React from 'react';
import {Placeholder as JSSPlaceholder, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';

//Set of components which shouldn't be filtered out
const exceptionComponents = ['VolunteerHeader'];

const Placeholder = ({name, rendering, sitecoreContext = {}, ...otherProps}) => {
	if (sitecoreContext.pageEditing) {
		//Switch out any components that are missing a datasource with the ErrorComponent
		rendering.placeholders[name].forEach(component => {
			if (component.componentName && !component.placeholders && !component.fields && !exceptionComponents.includes(component.componentName)) {
				component.fields = {
					componentName: component.componentName,
				};
				component.componentName = 'ErrorComponent';
			}
		});
	} else {
		//Filter out any components that are missing a datasource on live site
		rendering.placeholders[name] = rendering.placeholders[name].filter(
			component => {
				if (component.placeholders || component.fields || exceptionComponents.includes(component.componentName)) {
					return true;
				}
				console.error(`${component.componentName} is missing a datasource`);
				return false;
			},
		);
	}

	return <JSSPlaceholder name={name} rendering={rendering} {...otherProps} />;
};

export default withSitecoreContext()(Placeholder);
